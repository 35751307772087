<template>
  <div>
    <the-admin-layout-header headline="Accounts" />

    <v-card flat outlined width="700" class="ml-15 mt-7">
      <template>
        <v-data-table
          :headers="headers"
          :items="adminAdvisorList"
          :search="search"
          :items-per-page="5"
          class="title primary--text mt-2"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-row>
                <v-col cols="6">
                  <v-toolbar-title class="headline primary--text mt-6"
                    >Advisor Accounts ({{ adminAdvisorCount }})
                  </v-toolbar-title>
                </v-col>

                <v-col cols="2">
                  <v-card-text class="text-right"
                    ><v-icon
                      v-if="adminAdvisorList != ''"
                      color="success"
                      @click="dialogCreate = !dialogCreate"
                      class="display-1 mt-2"
                      >mdi-account-plus-outline</v-icon
                    >
                  </v-card-text>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    v-model="search"
                    prepend-inner-icon="mdi-account-search"
                    label="search"
                    outlined
                    hide-details
                    class="subtitle-2 primary--text mt-5"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-dialog persistent v-model="dialogSettings" max-width="300px">
                <v-card>
                  <v-card-title>
                    <span class="headline primary--text">Settings</span>
                  </v-card-title>

                  <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-card-text
                              class="subtitle-1 font-weight-bold primary--text ml-n4 mt-n8"
                            >
                              {{ profile.name }}
                            </v-card-text>
                          </v-col>
                          <v-col cols="12">
                            <v-select
                              v-model="profile.accountState"
                              :items="accountStateItems"
                              label="Account State"
                              dense
                              class="primary--text  mt-n2"
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text class="primary--text mb-n2" @click="close"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="success"
                          class="mr-n2 mb-n2"
                          @click="saveSettings"
                          >Save</v-btn
                        >
                      </v-card-actions>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-dialog>

              <v-dialog persistent v-model="dialogCreate" max-width="400px">
                <v-card>
                  <v-card-title>
                    <span class="headline primary--text ml-3 mt-4"
                      >Create a new advisor</span
                    >
                  </v-card-title>

                  <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-container>
                        <v-row>
                          <v-col cols="6">
                            <v-text-field
                              v-model="profile.firstName"
                              :rules="nameReqRules"
                              label="First name"
                          /></v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="profile.familyName"
                              :rules="nameReqRules"
                              label="Family name"
                            />
                          </v-col>
                          <v-col cols="12" class="mt-n8">
                            <v-text-field
                              v-model="profile.email"
                              :rules="emailReqRules"
                              prepend-icon="mdi-email"
                              label="E-Mail"
                            />
                          </v-col>
                          <v-col cols="12" class="mt-n8">
                            <v-text-field
                              v-model="profile.mobilePhone"
                              :rules="phoneReqRules"
                              prepend-icon="mdi-phone"
                              label="Mobile phone"
                            />
                          </v-col>
                        </v-row>
                      </v-container>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text class="primary--text" @click="close"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="success"
                          text
                          :loading="loading1"
                          :disabled="!valid || loading1"
                          @click="createItem"
                          >Create<template v-slot:loader>
                            <span class="custom-loader">
                              <v-icon color="success">mdi-camera-iris</v-icon>
                            </span>
                          </template></v-btn
                        >
                      </v-card-actions>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <template v-slot:item.advProfile="{ item }">
            <v-icon @click="openItem(item)" color="success"
              >mdi-account-edit-outline</v-icon
            >
          </template>

          <template v-slot:item.accState="{ item }">
            <div v-if="item.accountState == 'active'">
              <v-icon color="green" @click="editItem(item)">mdi-check</v-icon>
            </div>
            <div v-else-if="item.accountState == 'blocked'">
              <v-icon small color="red" @click="editItem(item)"
                >mdi-do-not-disturb</v-icon
              >
            </div>
            <div v-else-if="item.accountState == 'archived'">
              <v-icon color="success" @click="editItem(item)" class="title">
                mdi-archive
              </v-icon>
            </div>
            <div v-else>
              <v-icon color="success" @click="editItem(item)" class="title">
                mdi-delete-alert
              </v-icon>
            </div>
          </template>

          <!-- <template v-slot:item.onlineAccess="{ item }">
            <div v-if="item.accountState == 'active'">
              <v-icon>mdi-check</v-icon>
            </div>
            <div v-else>
              <v-icon small>
                mdi-do-not-disturb
              </v-icon>
            </div>
          </template> -->

          <template v-slot:no-data>
            <v-card flat>
              <v-card-text class="title font-weight-regular">
                You have no Advisors defined yet, let's define some ...
                <v-icon
                  color="success"
                  class="mb-4 display-1"
                  @click="dialogCreate = !dialogCreate"
                  >mdi-account-plus-outline</v-icon
                >
              </v-card-text>
            </v-card>
          </template>
        </v-data-table>
      </template>
    </v-card>
  </div>
</template>

<script>
import store from '@/vuex/store'
import { mapState } from 'vuex'
import { authComputed } from '@/vuex/helpers.js'
import TheAdminLayoutHeader from '@/components/TheAdminLayoutHeader.vue'

export default {
  name: 'adminAdvisors',

  // Navigation Route Guard to preload API Data before page is being shown
  beforeRouteEnter(routeTo, routeFrom, next) {
    store.dispatch('fetchAdvisors').then(() => {
      // making sure, when navigating to this page, the slider allways moves to "ACCOUNTS" ( tab = 0 )
      store.commit('SET_ADMIN_LAYOUT_TAB', 0)
      next()
    })
  },

  components: {
    TheAdminLayoutHeader
  },

  data() {
    return {
      valid: true,
      loader: null,
      loading1: false,
      dialogSettings: false,
      dialogCreate: false,
      search: '',
      nameRules: [
        v =>
          !v || (v && v.length <= 20) || 'Name must be less than 20 characters'
      ],
      nameReqRules: [
        v => !!v || 'Field is required',
        v =>
          !v || (v && v.length <= 20) || 'Name must be less than 20 characters'
      ],
      numberRules: [
        v => !v || /^(?=.*\d).+$/.test(v) || 'Requires numbers only'
      ],
      numberRulesCode: [
        v => !!v || 'Code is required',
        v =>
          (/^(?=.*\d).+$/.test(v) && v.length == 6) ||
          'Code must be 6 characters'
      ],
      phoneRules: [
        v => !v || /^[+]+\d+$/.test(v) || 'Use this form ... +64210123456'
      ],
      phoneReqRules: [
        v => !!v || 'Phone is required',
        v => !v || /^[+]+\d+$/.test(v) || 'Use this form ... +64210123456'
      ],
      emailReqRules: [
        v => !!v,
        v => !v || (!!v && /.+@.+\..+/.test(v)) || 'E-mail must be valid'
      ],
      headers: [
        {
          text: 'Name',
          align: 'left',
          value: 'name',
          width: 200,
          class: 'subtitle-1 primary--text'
        },
        {
          text: 'Profile',
          align: 'center',
          value: 'advProfile',
          sortable: false,
          class: 'subtitle-1 primary--text'
        },
        {
          text: 'Role',
          align: 'center',
          value: 'role',
          sortable: false,
          class: 'subtitle-1 primary--text'
        },
        {
          text: 'Account State',
          align: 'center',
          value: 'accState',
          sortable: false,
          class: 'subtitle-1 primary--text'
        }
        // {
        //   text: 'Access',
        //   align: 'center',
        //   value: 'onlineAccess',
        //   sortable: false,
        //   class: 'subtitle-1 primary--text'
        // }
      ],
      stateItems: {
        active: [
          {
            text: 'active - online access',
            value: 'active'
          },
          {
            text: 'blocked - no online access',
            value: 'blocked'
          }
        ],
        blocked: [
          { text: 'active - online access', value: 'active' },
          {
            text: 'blocked - no online access',
            value: 'blocked'
          },
          {
            text: 'inactive - archived',
            value: 'archived'
          }
        ],
        archived: [
          {
            text: 'blocked - no online access',
            value: 'blocked'
          },
          {
            text: 'inactive - archived',
            value: 'archived'
          },
          {
            text: 'inactive - deleted',
            value: 'deleted'
          }
        ],
        deleted: [
          {
            text: 'inactive - archived',
            value: 'archived'
          },
          {
            text: 'inactive - deleted',
            value: 'deleted'
          }
        ]
      }
    }
  },

  methods: {
    openItem(item) {
      console.log('Open Advisor', item)
      this.$store.dispatch('fetchAdvisor', item.sortKey).then(() => {
        this.$router.push('/admin/advisor-profile')
      })
    },

    editItem(item) {
      console.log('Edit Settings', item)
      this.$store.dispatch('fetchAdvisor', item.sortKey).then(() => {
        this.dialogSettings = true
      })
    },

    close() {
      this.dialogSettings = false
      this.dialogCreate = false
      this.$refs.form.reset()
      this.$store.dispatch('initAdminAdvisorProfile')
    },

    saveSettings() {
      this.$store.dispatch('updateAdvisor', this.adminAdvisorId).then(() => {
        this.dialogSettings = false
        this.$refs.form.reset()
      })
    },

    createItem() {
      if (this.$refs.form.validate()) {
        this.loader = 'loading1'
        this.loading1 = true
        this.$store.dispatch('createAdvisor').then(() => {
          this.loading1 = false
          this.dialogCreate = false
          this.$refs.form.reset()
          this.$store.dispatch('initAdminAdvisorProfile')
        })
      }
    }
  },

  computed: {
    profile: {
      get() {
        return this.adminAdvisorProfile.profile
      },
      set(attributes) {
        this.$store.commit('SET_ADMIN_ADVISOR_PROFILE', attributes)
      }
    },

    accountStateItems() {
      const state = this.adminAdvisorProfile.profile.accountState
      const tenAdm = [
        {
          text: 'active - online access',
          value: 'active'
        }
      ]
      // if item is the active Administrator, do not allow to block the account (otherwise you would cut yourself out of the App!!
      return this.adminAdvisorProfile.profile.role == 'TenAdm'
        ? tenAdm
        : this.stateItems[state]
    },

    ...mapState(['adminAdvisorProfile']),

    ...authComputed
  }
}
</script>

<style lang="scss" scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
